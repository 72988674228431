import revive_payload_client_4sVQNw7RlN from "/home/josed/Documents/CODE/relowcode/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/josed/Documents/CODE/relowcode/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/josed/Documents/CODE/relowcode/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/josed/Documents/CODE/relowcode/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/josed/Documents/CODE/relowcode/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/josed/Documents/CODE/relowcode/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/josed/Documents/CODE/relowcode/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/josed/Documents/CODE/relowcode/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/josed/Documents/CODE/relowcode/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_jBAFIEN7H9 from "/home/josed/Documents/CODE/relowcode/app/node_modules/@hebilicious/authjs-nuxt/dist/runtime/plugin.mjs";
import iconify_jnBdnrGW5R from "/home/josed/Documents/CODE/relowcode/app/plugins/iconify.js";
import slvuetree_lh7rRdxUOQ from "/home/josed/Documents/CODE/relowcode/app/plugins/slvuetree.ts";
import mermaid_PmF6W0YmpC from "/home/josed/Documents/CODE/relowcode/app/plugins/mermaid.ts";
import swal_qDj5XZX5pa from "/home/josed/Documents/CODE/relowcode/app/plugins/swal.js";
import vueGoogleMaps_8xmoAwKZvr from "/home/josed/Documents/CODE/relowcode/app/plugins/vueGoogleMaps.ts";
import vuetify_7h9QAQEssH from "/home/josed/Documents/CODE/relowcode/app/plugins/vuetify.ts";
import vuewriter_client_GV6t2arglw from "/home/josed/Documents/CODE/relowcode/app/plugins/vuewriter.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_jBAFIEN7H9,
  iconify_jnBdnrGW5R,
  slvuetree_lh7rRdxUOQ,
  mermaid_PmF6W0YmpC,
  swal_qDj5XZX5pa,
  vueGoogleMaps_8xmoAwKZvr,
  vuetify_7h9QAQEssH,
  vuewriter_client_GV6t2arglw
]